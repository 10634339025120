'use client';

import styled, { css } from 'styled-components';
import Item from './Item';
import { Row } from '@/components/core/flexbox';
import { getActivePage } from '@/utils/getActivePage';
import { usePathname } from 'next/navigation';
import React from 'react';

const Items = ({ items }) => {
  const less = items.length <= 4;
  const pathname = usePathname();

  return (
    <Wrapper $line={less}>
      {items.map((item) => {
        const isCurrentPage = getActivePage(item.link, pathname);

        if (isCurrentPage) {
          return null;
        }

        return <Item {...item} key={item.link} line={less} />;
      })}
    </Wrapper>
  );
};

export default Items;

const Wrapper = styled(Row)`
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  margin: 0 auto;

  ${(props) =>
    props.$line &&
    css`
      flex: 1;
      max-width: 100%;
    `};
`;
