import styled from 'styled-components';

import Modal from '@/components/shared/Modal';
import Close from '@/public/assets/icons/circle-close.svg';
import useVideoTracking from '@/utils/useVideoTracking';
import { memo, useCallback } from 'react';

const CloseButton = styled.button`
  position: fixed;
  content: '';
  top: -64px;
  right: 0;
  width: 48px;
  height: 48px;
  z-index: 100001;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.3s ease;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    top: -48px;
    width: 32px;
    height: 32px;
  }
`;
const IconClose = styled(Close)`
  width: 16px;
  height: 16px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    width: 20px;
    height: 20px;
  }
`;

const Video = styled.video`
  position: relative;
  width: 100%;
  max-width: 1264px;
  max-height: 78vh;
`;

const VideoPlayerModal = ({ modalIsOpen, closeModal, videoURL }) => {
  const { onVideoExit, ...trackVideo } = useVideoTracking({ src: videoURL });

  const onClose = useCallback(() => {
    onVideoExit();
    closeModal();
  }, [onVideoExit, closeModal]);

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={onClose}>
      {modalIsOpen && (
        <CloseButton aria-label="Close" type="button" onClick={onClose} data-testid="closeModal">
          <IconClose />
        </CloseButton>
      )}
      <Video
        controls
        autoPlay
        playsInline
        data-testid="player"
        controlsList="nodownload"
        {...trackVideo}
      >
        <source src={videoURL} />
      </Video>
    </Modal>
  );
};

export default memo(VideoPlayerModal);
