import styled from 'styled-components';

// Components
import { Marquee } from '@/components/core';

export const Header = styled.header`
  justify-items: center;
  display: grid;
  gap: 24px;
  text-align: center;
  margin-bottom: 80px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 64px;
    padding: 0 24px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    gap: 16px;
    margin-bottom: 40px;
  }
`;

export const StyledMarquee = styled(Marquee)`
  height: 80px !important;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 64px !important;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 48px !important;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  height: 80px;
  padding-left: 40px;
  padding-right: 40px;

  img {
    object-fit: contain;
    height: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 64px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 48px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;
