import { Section } from '@/components/core';
import CounterComponent from './CounterComponent';
import { Container } from '@/components/core/flexbox';
import { Description, StyledTitle } from './styles';
import theme from '@/utils/styles-variables';

function CounterSection({ id, value, title, description, ...rest }) {
  return (
    <Section id={`counter-${id}`} color={theme.colors.neutral.default} {...rest}>
      <Container>
        <CounterComponent value={value} />
        <StyledTitle as="h3" size="xl">
          {title}
        </StyledTitle>
        <Description as="p" isMarkdown={false}>
          {description}
        </Description>
      </Container>
    </Section>
  );
}

export default CounterSection;
