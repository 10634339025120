import styled, { css } from 'styled-components';

import { UpperText } from '@/components/core/Title';
import { Container } from '@/components/core/flexbox';
import { Section } from '@/components/core';
import Image from 'next/image';

export const CustomSection = styled(Section)`
  position: relative;
  ${(props) =>
    !props.$isSuccess &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.min_lg}px) {
        margin-bottom: 80px;
      }
    `};

  &::before {
    background-color: ${(props) => props.theme.colors.neutral.default};
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    transform: translateX(-50%);
    width: 100vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    &::before {
      bottom: 750px;
    }
  }

  ${(props) =>
    props.$isSuccess &&
    css`
      &::before {
        display: none;
      }
    `};
`;

export const CustomContainer = styled(Container)`
  align-items: start;
  display: grid;
  column-gap: 80px;
  grid-template-columns: 1fr 1fr;
  padding-top: calc(var(--header-height) + 56px);
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    grid-template-columns: 1fr;
    margin-bottom: 0;
    row-gap: 64px;
  }

  ${(props) =>
    props.$isSuccess &&
    css`
      align-items: center;
      margin-bottom: 0;
    `};
`;

export const CustomHeader = styled.header`
  display: grid;
  gap: 24px;
  justify-items: start;
  position: relative;
  z-index: 1;

  p {
    color: ${(props) => props.theme.colors.grey[800]};
  }

  ${(props) =>
    !props.$isSuccess &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.min_lg}px) {
        padding-top: 64px;
      }
    `};
`;

export const SuccessImage = styled(Image)`
  border-radius: 8px;
  aspect-ratio: 592 / 333;
  height: auto;
  object-fit: cover;
  width: 100%;
`;

export const StyledUpperText = styled(UpperText)`
  padding-bottom: 0;
`;
