'use client';

import { ReactEventHandler, useCallback, useRef } from 'react';
import { usePathname } from 'next/navigation';
import { EVENT_NAMES, trackVideoPlayed } from './track';

type UseVideoTrackingProps = {
  src?: string;
};

type trackingEvent = () => void;

type VideoEvents = {
  onCanPlay: ReactEventHandler<HTMLVideoElement>;
  onPlay: ReactEventHandler<HTMLVideoElement>;
  onPause: ReactEventHandler<HTMLVideoElement>;
  onTimeUpdate: ReactEventHandler<HTMLVideoElement>;
  onEnded: ReactEventHandler<HTMLVideoElement>;
  onVolumeChange: ReactEventHandler<HTMLVideoElement>;
  onVideoExit: trackingEvent;
};

export default function useVideoTracking({ src }: UseVideoTrackingProps): VideoEvents {
  const pathname = usePathname();
  const sessionId = useRef<string>(Math.random().toString(36));
  const videoFirstPlayBack = useRef<boolean>(true);
  const videoLength = useRef<number>(0);
  const videoVolume = useRef<number>(100);
  const videoProgress = useRef<number>(0);

  const trackVideo = useCallback(
    ({ event, ...eventData }) => {
      if (!src) {
        return;
      }

      trackVideoPlayed(event, {
        src,
        sessionId: sessionId.current,
        url: pathname,
        position: videoProgress.current,
        length: videoLength.current,
        sound: videoVolume.current,
        ...eventData,
      });
    },
    [pathname, src],
  );

  return {
    onCanPlay: (e) => {
      const video = e.target as HTMLVideoElement;

      videoLength.current = Math.ceil(video?.duration || 0);
      videoVolume.current = Math.ceil(video?.volume * 100);
    },
    onPlay: () => {
      const event = videoFirstPlayBack.current
        ? EVENT_NAMES.VIDEO_PLAY_START
        : EVENT_NAMES.VIDEO_PLAY_RESUME;

      videoFirstPlayBack.current = false;

      trackVideo({
        event,
      });
    },
    onEnded: () => {
      videoFirstPlayBack.current = true;
      videoProgress.current = videoLength.current;

      trackVideo({
        event: EVENT_NAMES.VIDEO_COMPLETED,
      });
    },
    onPause: () => {
      if (videoProgress.current >= videoLength.current) {
        return;
      }

      trackVideo({
        event: EVENT_NAMES.VIDEO_PAUSE,
      });
    },
    onVolumeChange: (e) => {
      const video = e.target as HTMLVideoElement;
      const volume = video?.muted ? 0 : video?.volume;

      videoVolume.current = Math.ceil(volume * 100);
    },
    onTimeUpdate: useCallback((e) => {
      const video = e.target as HTMLVideoElement;

      videoProgress.current = Math.ceil(video?.currentTime);
    }, []),
    onVideoExit: () => {
      if (videoProgress.current >= videoLength.current) {
        return;
      }

      trackVideo({
        event: EVENT_NAMES.VIDEO_EXITED,
      });
    },
  };
}
