'use client';

// Components
import Image from 'next/image';
import { Section } from '@/components/core';

// Utils
import getImgSrcWorkaround from '@/utils/images';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

// Styles
import { StyledMarquee, ImageWrapper } from './style';
import Intro from '../Intro';

const getWidth = (width, height, isDesktop, isTablet) => {
  if (isDesktop) {
    return (width * 80) / height;
  }
  if (isTablet) {
    return (width * 64) / height;
  }
  return (width * 48) / height;
};

const Investors = ({ content, badge = true, gradient, titleSize }) => {
  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_lg}px)`);
  const isTablet = useBetterMediaQuery(
    `(min-width: ${theme.breakpoints.min_md}px) and (max-width: ${theme.breakpoints.max_md}px)`,
  );

  if (!content || !content.companies || !content.companies.length) return null;

  const { upperLabel, title, companies } = content;

  return (
    <Section gradient={gradient}>
      <Intro
        upperLabel={upperLabel}
        upperLabelVariant={badge ? 'badge' : null}
        title={title}
        titleSize={titleSize}
        innerSpacer
      />
      <StyledMarquee content={companies} full>
        {companies?.map((company) => {
          const width = getWidth(
            company.image.data.attributes.width,
            company.image.data.attributes.height,
            isDesktop,
            isTablet,
          );

          return (
            <ImageWrapper key={company.image.data.attributes.url} data-testid="item">
              <Image
                data-testid="companies-image"
                src={getImgSrcWorkaround(company.image.data.attributes.url)}
                alt={company.image.data.attributes.alternativeText || ''}
                width={width}
                height={isDesktop ? 80 : isTablet ? 64 : 48}
                quality={100}
              />
            </ImageWrapper>
          );
        })}
      </StyledMarquee>
    </Section>
  );
};

export default Investors;
