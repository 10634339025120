import { useId } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import InputLabel from './InputLabel';
import ErrorLabel from './ErrorLabel';

const PhoneNumber = ({
  className,
  placeholder,
  label,
  field: { name, value },
  form: { setFieldValue, setFieldTouched },
  onChange,
  onCountryChange,
  disabled,
  error,
  errorLabel,
  ...field
}) => {
  const id = useId();
  const errorId = useId();

  return (
    <div className={cn(['phone-input-wrap', className])}>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}

      <PhoneInput
        id={id}
        defaultCountry="US"
        className={cn('phone-input', { error })}
        value={value}
        placeholder={placeholder}
        onChange={(phoneNumber) =>
          onChange ? onChange(phoneNumber) : setFieldValue(name, phoneNumber || '')
        }
        onCountryChange={onCountryChange}
        onBlur={() => setFieldTouched(name, true)}
        disabled={disabled}
        limitMaxLength
        /*internationalIcon={InternationalIcon}*/
        autoComplete="tel"
        {...(error && errorLabel ? { 'aria-invalid': true, 'aria-describedby': errorId } : {})}
        {...field}
      />

      {error && errorLabel && (
        <ErrorLabel id={errorId} error={error}>
          {errorLabel}
        </ErrorLabel>
      )}
    </div>
  );
};
const StyledPhoneNumber = styled(PhoneNumber)`
  margin-bottom: 20px;

  .phone-input {
    position: relative;

    .PhoneInputCountry {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }

    .PhoneInputCountryIcon {
      svg {
        height: 18px;
      }
    }

    .PhoneInputCountrySelectArrow {
      border-bottom: 1px solid rgba(25, 47, 65, 0.5);
      border-right: 1px solid rgba(25, 47, 65, 0.5);
      width: 9px;
      height: 9px;
      border-bottom-width: 2px;
      border-right-width: 2px;
      margin-bottom: 4px;
      margin-left: 13px;
      margin-right: 10px;
      opacity: 1;
    }

    .PhoneInputInput {
      height: 48px;
      background-color: ${(props) => props.theme.colors.inputs.bg};
      color: ${(props) => props.theme.colors.inputs.text};
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      padding: 0 20px 0 74px;
      border: 1px solid rgba(25, 47, 65, 0.15);
      border-radius: 4px;

      &::placeholder {
        color: rgba(48, 38, 29, 0.5);
      }

      &:hover {
        border-color: ${(props) => props.theme.colors.purple};
      }

      &:disabled,
      &.disabled {
        color: rgba(25, 47, 65, 0.3);
        background-color: rgba(25, 47, 65, 0.05);
      }
    }

    &.PhoneInput--focus .PhoneInputInput,
    .PhoneInputInput:focus {
      border-color: ${(props) => props.theme.colors.purple};
      box-shadow: #ae95da 0 0 4px;
    }

    &.error .PhoneInputInput {
      color: ${(props) => props.theme.colors.inputs.error};
      border-color: ${(props) => props.theme.colors.inputs.error};
    }
  }
`;

export default StyledPhoneNumber;
