import styled from 'styled-components';
import { Paragraph } from '@swordhealth/ui-corporate';

const InputLabel = styled(Paragraph).attrs({ forwardedAs: 'label' })`
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
`;

export default InputLabel;
