import { Container } from '@swordhealth/ui-corporate';

import { Section } from '@/components/core';

import Items from './Items';
import Intro from '../Intro';

export default function ProductSuite({ content, spacerTop }) {
  const { item, ...intro } = content;

  return (
    <Section spacerTop={spacerTop}>
      <Intro {...intro} innerSpacer />
      <Container>
        <Items items={item} />
      </Container>
    </Section>
  );
}
