const inputsFocus = (props) => `
  outline: none;
  border-color: ${props.theme.colors.inputs.borderFocus};
`;

const inputs = (props) => `
	width: 100%;
	height: 48px;
	background-color: ${props.theme.colors.inputs.bg};
	color: ${props.theme.colors.inputs.text};
	font-size: 16px;
	font-weight: 500;
	line-height: 48px;
	padding: 0 20px;
	border: 1px solid ${props.theme.colors.inputs.border};
	border-radius: 4px;
	transition: all 0.3s ease;

  &.textarea {
		height: 100px;
    line-height: 28px;
		resize: none;
		padding: 10px 20px;
	}

	&::placeholder {
		color: ${props.theme.colors.inputs.placeholder};
	}
	
	&:hover{
	  ${inputsFocus(props)}
	}

  &:focus{
		${inputsFocus(props)}
	}

	&.error {
		color: ${props.theme.colors.inputs.error};
		border-color: ${props.theme.colors.inputs.error};
	}
`;

export { inputs, inputsFocus };
