import styled from 'styled-components';
import cn from 'classnames';

import ErrorIcon from '../icons/error.svg';

const ErrorLabel = ({ error, children, className, ...rest }) => (
  <div className={cn(className, { error })} {...rest}>
    <div className="error-icon">
      <ErrorIcon />
    </div>
    <div className="error-label">{children}</div>
  </div>
);

const ErrorLabelStyled = styled(ErrorLabel)`
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  margin-top: 4px;

  &.error {
    visibility: visible;
    height: 20px;
    opacity: 1;
  }

  .error-icon {
    margin-right: 6px;
  }

  .error-label {
    color: ${(props) => props.theme.colors.inputs.error};
    font-size: 12px;
    line-height: 20px;
  }
`;

export default ErrorLabelStyled;
