import Image from 'next/image';

import { Section } from '@/components/core';
import Intro from '../Intro';
import { Content } from '../Legal';
import { getImgData } from '@/utils/images';
import {
  StyledContainer,
  StyledFooter,
  StyledImageWrapper,
  StyledSignature,
  StyledSignatureLabel,
} from './styles';

const SIGNATURE_WIDTH = 200;

function Article({ id, content, signature, ...rest }) {
  const imageData = getImgData(signature?.image);
  const signatureData = getImgData(signature?.signature);

  return (
    <Section id={`article-${id}`} as="div" {...rest}>
      <StyledContainer forwardedAs="article">
        <Intro title={content.title} size="sm" as="header" innerSpacer />
        <Content content={content?.content} />
        {signature && (
          <StyledFooter>
            {imageData && (
              <StyledImageWrapper>
                <Image
                  src={imageData.url}
                  alt={imageData.alternativeText || ''}
                  fill
                  sizes="140px"
                />
              </StyledImageWrapper>
            )}
            <div>
              {signatureData && (
                <StyledSignature
                  src={signatureData.url}
                  alt={signatureData.alternativeText || ''}
                  width={SIGNATURE_WIDTH}
                  height={(signatureData.height * SIGNATURE_WIDTH) / signatureData.width}
                />
              )}
              {signature?.label && (
                <StyledSignatureLabel size="sm">{signature.label}</StyledSignatureLabel>
              )}
            </div>
          </StyledFooter>
        )}
      </StyledContainer>
    </Section>
  );
}

export default Article;
