import styled, { css } from 'styled-components';
import { Paragraph } from '@/components/core';

export const Wrapper = styled.div`
  display: flex;
  gap: 131px;

  ${({ $imageRight }) =>
    $imageRight &&
    css`
      flex-direction: row-reverse;
    `}

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    gap: 64px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    gap: 40px;
  }
`;
export const WrapperInfo = styled.div`
  display: flex;
  align-self: center;
  gap: 32px;
  width: 100%;
  max-width: 490px;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 100%;
    align-items: center;
    gap: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    gap: 24px;
  }
`;
export const InfoContent = styled.ul`
  display: grid;
  gap: 32px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    grid-template-columns: initial;
    gap: 24px;
  }
`;
export const ImageContent = styled.figure`
  img {
    border-radius: 8px;
    width: 100%;
    height: auto;
    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      aspect-ratio: 5/4;
      object-fit: cover;
    }
  }
`;

export const Content = styled.li`
  display: grid;
  gap: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 8px;
  }
`;
export const Footer = styled.div`
  width: 100%;
  max-width: 490px;
  padding: 24px 32px 32px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  display: grid;
  gap: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    max-width: 243px;
  }
`;
export const FooterTitle = styled(Paragraph).attrs({ size: 'sm' })`
  line-height: ${(props) => props.theme.font.body.size.md};
  color: ${(props) => props.theme.colors.grey.default};
  font-weight: ${(props) => props.theme.font.weight.semibold};
  text-transform: uppercase;
`;
