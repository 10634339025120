import { Field } from 'formik';

import Textbox from '@/components/core/forms/input/Textbox';
import PhoneNumber from '@/components/core/forms/input/PhoneNumber';
import { isValidPhoneNumber } from 'react-phone-number-input';

export default function FormFields({ values, errors, touched }) {
  return (
    <>
      <Field
        component={Textbox}
        type="text"
        name="firstName"
        label="First Name*"
        placeholder="Your first name"
        autoComplete="given-name"
        required
        validate={(value) => {
          const nameRegex = /^[A-Za-z-\s\u00c0-\u017e]+$/;
          return !nameRegex.test(value.trim());
        }}
        error={errors.firstName && touched.firstName}
        errorLabel={values.firstName.trim() ? 'First Name is invalid' : 'This field is required'}
      />
      <Field
        component={Textbox}
        type="text"
        name="lastName"
        label="Last Name*"
        placeholder="Your last name"
        autoComplete="family-name"
        required
        validate={(value) => {
          const nameRegex = /^[A-Za-z-\s\u00c0-\u017e]+$/;
          return !nameRegex.test(value.trim());
        }}
        error={errors.lastName && touched.lastName}
        errorLabel={values.lastName.trim() ? 'Last Name is invalid' : 'This field is required'}
      />
      <Field
        component={Textbox}
        type="text"
        name="company"
        label="Company*"
        placeholder="Your company name"
        autoComplete="organization"
        required
        validate={(value) => {
          const alphabeticRegex = /^[A-Za-z\d\s\u00c0-\u017e]+$/;
          return !alphabeticRegex.test(value.trim());
        }}
        error={errors.company && touched.company}
        errorLabel={values.company.trim() ? 'Company name is invalid' : 'This field is required'}
      />
      <Field
        component={Textbox}
        type="text"
        name="jobTitle"
        label="Job Title*"
        autoComplete="organization-title"
        required
        placeholder="Your job title"
        validate={(value) => {
          const nameRegex = /^[A-Za-z-\s\u00c0-\u017e]+$/;
          return !nameRegex.test(value.trim());
        }}
        error={errors.jobTitle && touched.jobTitle}
        errorLabel={values.jobTitle.trim() ? 'Job Title is invalid' : 'This field is required'}
      />
      <Field
        component={Textbox}
        type="email"
        name="email"
        label="Work Email Address*"
        placeholder="Your work email address"
        autoComplete="email"
        required
        validate={(value) => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
          return !emailRegex.test(value);
        }}
        error={errors.email && touched.email}
        errorLabel={
          values.jobTitle.trim() ? 'Work Email Address is invalid' : 'This field is required'
        }
      />
      <Field
        component={PhoneNumber}
        name="phone"
        label="Phone Number"
        placeholder="123-456-7890"
        autoComplete="tel"
        validate={(value) => (value ? !isValidPhoneNumber(value) : false)}
        error={errors.phone && touched.phone}
        errorLabel="Invalid Phone format"
      />
    </>
  );
}
