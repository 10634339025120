import styled, { css } from 'styled-components';

import { LinkButton } from '@/components/core/Button';

import useButtonTracking from '@/utils/useButtonTracking';

const Item = ({ title, description, link, line }) => {
  const trackButtonClicked = useButtonTracking({
    url: link,
    location: 'header',
    label: 'Know more',
  });

  return (
    <Wrapper $line={line}>
      <div>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <LinkButton href={link} $primary onClick={trackButtonClicked}>
          Know more
        </LinkButton>
      </div>
    </Wrapper>
  );
};

export default Item;

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutral.default};
  border-radius: 8px;
  padding: 40px 30px;
  width: calc(25% - 18px);

  ${(props) =>
    props.$line &&
    css`
      flex: 1;
      width: unset;
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex: unset;
    width: calc(50% - 12px);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    padding: 24px;
  }
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.grey.default};
  margin-bottom: 8px;
`;
