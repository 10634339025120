import { useId } from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import { inputs } from '@/utils/mixins';

import InputLabel from './InputLabel';
import ErrorLabel from './ErrorLabel';

const Textbox = ({
  className,
  label,
  type,
  placeholder,
  value,
  name,
  onChange,
  disabled,
  error,
  errorLabel,
  field,
  required,
}) => {
  const id = useId();
  const errorId = useId();
  const TagName = type === 'textarea' ? 'textarea' : 'input';

  return (
    <div className={cn(['textbox-wrap', className])}>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}

      <TagName
        id={id}
        className={cn('textbox', type, { error })}
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
        required={required}
        {...field}
        {...(error && errorLabel ? { 'aria-invalid': true, 'aria-describedby': errorId } : {})}
      />

      {error && errorLabel && (
        <ErrorLabel id={errorId} error={error}>
          {errorLabel}
        </ErrorLabel>
      )}
    </div>
  );
};

const TextboxStyled = styled(Textbox)`
  margin-bottom: 20px;

  .textbox {
    ${inputs}
  }
`;

export default TextboxStyled;
