import styled from 'styled-components';
import { GAP } from '@/components/core/Section/Wrapper';

export const FormWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 20px 50px rgba(239, 233, 230, 0.5);
  border-radius: 8px;
  padding: 64px;
  transform-style: preserve-3d;
  margin-bottom: calc(-216px - ${GAP}px);

  form {
    display: grid;
    gap: 32px;
  }

  label {
    font-weight: 600;
  }

  .phone-input-wrap,
  .textbox-wrap {
    margin-bottom: 0;
  }

  .fullWidth,
  .customButton {
    justify-self: start;
    margin-top: 16px;
  }
  .customButton {
    width: fit-content;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    &::before {
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(239, 233, 230, 0) 100%
      );
      border-radius: 50%;
      content: '';
      display: block;
      height: 800px;
      position: absolute;
      top: -56px;
      left: 50%;
      transform: translate3d(-50%, 0, -1px);
      width: 800px;

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        height: 120vw;
        width: 120vw;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    margin-left: -24px;
    padding: 48px 24px;
    width: 100vw;

    .fullWidth,
    .customButton {
      width: 100%;
    }
  }
`;
