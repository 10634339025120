import { Video } from '@swordhealth/ui-corporate';
import useReducedMotion from '@/utils/useReducedMotion';

import { Section, SectionHeader } from '@/components/core';
import { Container } from '@/components/core/flexbox';

import styles from './styles.module.css';

function Predict({ content }) {
  const { sectionHeader, video } = content;
  const prefersReducedMotion = useReducedMotion();

  return (
    <Section>
      <Container className={styles.customContainer}>
        <SectionHeader content={sectionHeader} oldLabel />
        <Video
          className={styles.video}
          autoPlay={!prefersReducedMotion}
          play={!prefersReducedMotion}
          controls={false}
          objectFit="cover"
          src={video.url}
          type={video.type}
          playsInline
          loop
          muted
        />
      </Container>
    </Section>
  );
}

export default Predict;
