'use client';

import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Person from './Person';
import { Container } from '@/components/core/flexbox';

const TeamContainer = styled(Container)`
  max-width: 1064px;
`;

const Grid = styled.div`
  --columns: 4;
  --gap: 16px;
  --gap-bottom: 71px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: var(--gap);
  row-gap: var(--gap-bottom);

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    --columns: 3;
    --gap-bottom: 45px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    --columns: 2;
  }

  ${(props) =>
    props.$spacerTop &&
    `
    margin-top: 80px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      margin-top: 64px;
    }
  
    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      margin-top: 40px;
    }
  `}
`;

const Team = ({ teamItems, fixedFirst, randomizeItems = true, spacerTop = true }) => {
  const [randomTeamOrder, setRandomTeam] = useState(null);

  useEffect(() => {
    if (!randomizeItems) {
      setRandomTeam(teamItems);
      return;
    }

    setRandomTeam(teamItems.slice(fixedFirst ? 1 : 0).sort(() => (Math.random() > 0.5 ? 1 : -1)));
  }, [fixedFirst, randomizeItems, teamItems]);

  if (!randomTeamOrder) return null;

  return (
    <TeamContainer>
      <Grid $spacerTop={spacerTop}>
        {fixedFirst && <Person {...teamItems[0]} key={teamItems[0].id} />}

        {randomTeamOrder.map((person) => (
          <Person {...person} key={person.id} />
        ))}
      </Grid>
    </TeamContainer>
  );
};

export default Team;
