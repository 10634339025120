import styled from 'styled-components';

import Image from 'next/image';
import { getImgData } from '@/utils/images';
import { Paragraph } from '@/components/core';

const ContentStyle = styled.section`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  gap: 24px;
  display: grid;
  align-content: baseline;

  img {
    border-radius: 8px;
    aspect-ratio: 592 / 333;
    height: auto;
    object-fit: cover;
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.min_lg}px) {
    margin: 0;
    padding-right: 40px;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: ${(props) => props.theme.colors.grey[800]};

  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
`;

const Content = ({ info, image }) => {
  const imageData = getImgData(image);

  return (
    <ContentStyle>
      {imageData && (
        <Image
          src={imageData.url}
          alt={imageData.alternativeText || ''}
          width={imageData.width}
          height={imageData.height}
        />
      )}
      <StyledParagraph
        highlightLinks
        markdownProps={{
          elements: [
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'ul',
            'ol',
            'li',
            'blockquote',
            'p',
            'em',
            'del',
            'code',
            'img',
            'a',
            'u',
          ],
          components: {
            h1: 'h2',
            h2: 'h3',
          },
        }}
      >
        {info}
      </StyledParagraph>
    </ContentStyle>
  );
};

export default Content;
