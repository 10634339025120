import styled from 'styled-components';
import { Paragraph, Title } from '@/components/core';

export const Number = styled(Title)`
  padding: 16px 56px;
  border-radius: 100px;
  color: ${(props) => props.theme.colors.primary.dark};
  background-color: ${(props) => props.theme.colors.primary.light};
  width: fit-content;
  margin: auto;
  font-variant-numeric: tabular-nums;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 8px 40px;
  }
`;

export const StyledTitle = styled(Title)`
  text-align: center;
  margin-top: 24px;
`;

export const Description = styled(Paragraph)`
  text-align: center;
  margin-top: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 180px;
  }
`;
