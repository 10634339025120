import styled from 'styled-components';
import Image from 'next/image';

import { Container } from '@/components/core/flexbox';
import { Paragraph } from '@/components/core';

export const StyledContainer = styled(Container)`
  max-width: 762px;
  position: relative;
`;

export const StyledFooter = styled.footer`
  align-items: center;
  display: grid;
  gap: 24px;
  grid-template-columns: auto 1fr;
  margin-top: 40px;
`;

export const StyledImageWrapper = styled.figure`
  aspect-ratio: 1 / 1;
  border-radius: 99999px;
  position: relative;
  overflow: hidden;
  width: 160px;

  img {
    object-fit: cover;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 140px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100px;
  }
`;

export const StyledSignature = styled(Image)`
  height: auto;
  max-width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 150px;
  }
`;

export const StyledSignatureLabel = styled(Paragraph)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: ${(props) => props.theme.font.body.size.xs};
  }
`;
