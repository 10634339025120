import Image from 'next/image';
import { memo } from 'react';

import { bustMediaCache, Video } from '@swordhealth/ui-corporate';

function Media({ alternativeText = '', mime, objectFit = 'cover', url, updatedAt, ...props }) {
  const src = bustMediaCache(url, updatedAt);

  if (!src || !mime) {
    return null;
  }

  if (mime.includes('video')) {
    return (
      <Video {...props} url={src} mime={mime} objectFit={objectFit} data-testid="media-video" />
    );
  }

  if (mime.includes('svg')) {
    return <img {...props} src={src} alt={alternativeText} data-testid="media-svg" />;
  }

  if (mime.includes('image')) {
    return (
      <Image
        {...props}
        src={src}
        alt={alternativeText}
        fill={!props.width}
        data-testid="media-image"
      />
    );
  }

  return null;
}

export default memo(Media);
