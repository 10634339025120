import rehypeSlug from 'rehype-slug';
import { Paragraph } from '@/components/core';
import moment from 'moment';

const Content = ({ content, updatedAt }) => {
  return (
    <section>
      <Paragraph
        highlightLinks
        markdownProps={{
          rehypePlugins: [rehypeSlug],
          elements: [
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'ul',
            'ol',
            'li',
            'blockquote',
            'p',
            'em',
            'del',
            'code',
            'img',
            'a',
            'u',
          ],
          components: {
            h1: 'h2',
            h2: 'h3',
          },
        }}
      >
        {content}
      </Paragraph>
      {updatedAt && (
        <Paragraph isMarkdown={false} as="footer" className="timestamp">
          Last updated on and effective on: {moment(updatedAt).format('MMMM DD, YYYY')}
        </Paragraph>
      )}
    </section>
  );
};

export default Content;
