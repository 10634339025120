import QueryString from 'qs';

import cmsClient from '@/lib/cms-client';
import NewsRoomsService from './news-room';
import BlogService from './blog';
import { hasGatedCookie } from '@/app/api/resources/gated/actions';

export async function getResources() {
  const query = {
    populate: [
      'seo',
      'seo.image',
      'header',
      'featuredItem',
      'featuredItem.image',
      'featuredItem.webinarPost',
      'featuredItem.clinicalPost',
      'featuredItem.whitePaperPost',
      'featuredItem.caseStudyPost',
      'featuredCategories',
      'featuredCategories.categoryName',
      'featuredCategories.icon',
      'featuredCategories.title',
      'featuredCategories.description',
      'featuredCategories.link',
      'whitePapers',
      'webinars',
      'studies',
      'caseStudies',
    ],
  };
  const data = await cmsClient(
    `/resource?${QueryString.stringify(query)}`,
    {
      next: { revalidate: 120 },
    },
    true,
  );

  return data?.data?.attributes ? { ...data.data.attributes } : null;
}

export async function getLookingFor() {
  const query = {
    populate: ['lookingFor', 'lookingFor.slider'],
  };

  const data = await cmsClient(`/home-page?${QueryString.stringify(query)}`, {
    next: { revalidate: 600 },
  });

  return data?.data?.attributes ? { ...data.data.attributes } : null;
}

export async function getResourcesBlogPage(slug) {
  const query = {
    populate: ['header', 'seo', 'seo.image'],
  };
  const data = await cmsClient(
    `/${slug}?${QueryString.stringify(query)}`,
    {
      next: { revalidate: 120 },
    },
    true,
  );

  return { ...data.data.attributes };
}

export async function getFile(fileUrl, slug, extension = 'pdf') {
  const name = `${slug}.${extension}`;
  const response = await fetch(`${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}/api/read-file`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ fileUrl, name }),
  });

  const blob = await response.blob();

  return {
    name,
    blob,
  };
}

function mapPayload(payload) {
  return {
    firstName: payload.firstName,
    lastName: payload.lastName,
    email: payload.email,
    title: payload.jobTitle,
    company: payload.company,
    phone: payload.phone,
    formSlug: payload.formSlug,
    UTM_Source_Last__c: payload.utm_source || '',
    UTM_Medium_Last__c: payload.utm_medium || '',
    UTM_Campaign_Last__c: payload.utm_campaign || '',
    UTM_Content_Last__c: payload.utm_content || '',
  };
}

export async function registerResource(payload) {
  // Payload: { firstName, lastName, email, jobTitle, company, phone, formSlug }
  const mappedPayload = mapPayload(payload);

  return fetch(`${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}/api/resources/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(mappedPayload),
  });
}

export async function getGatedResource({ query, postType }) {
  const { slug, resource_token, preview } = query;
  const unlocked = await hasGatedCookie(slug);

  const shared = await NewsRoomsService.getShared();
  const post = await BlogService.getBlogPost({
    slug,
    postType: postType.name,
    getGated: unlocked,
    token: resource_token,
    preview,
  });

  const gatedContent = post?.gatedContent?.[0];
  const bypassGatedScreen = unlocked || post.accessToken === resource_token;
  const isArticle = gatedContent?.__component === 'resources.gated-article';
  const displayArticle = !gatedContent || (bypassGatedScreen && isArticle);

  return {
    isArticle: !gatedContent || isArticle,
    displayArticle,
    displayGatedSuccess: bypassGatedScreen,
    post,
    postType,
    shared,
    slug,
    withoutMenu: !displayArticle,
  };
}

export async function getResourcesBlog({ parentPage, postType, pageLimit = 12 }) {
  const content = await getResourcesBlogPage(parentPage);
  const shared = await NewsRoomsService.getShared();
  const highlightedPost = await BlogService.getLatestPost({
    postType: postType.name,
  });
  const postsLists = await BlogService.getPosts({
    postType: postType.name,
    page: 0,
    limit: pageLimit,
  });

  return {
    content,
    highlightedPost,
    postsLists,
    shared,
    pageLimit,
    postType,
  };
}
