'use client';

import { useState } from 'react';
import IconPlay from '@swordhealth/ui-corporate/icons/system/play-solid.svg';

import useReducedMotion from '@/utils/useReducedMotion';
import VideoPlayerModal from '@/components/shared/VideoPlayerModal';

import getImgSrcWorkaround, { getImgData } from '@/utils/images';

import { Section, Title } from '@/components/core';
import { Wrapper, CustomContainer, VideoContainer, Content, TextContainer, Circle } from './styles';
import theme from '@/utils/styles-variables';
import { Button } from '@/components/core/Button';

const Video = ({ content, withBgColor = false, withCircleBackground = false }) => {
  const { buttonLabel, previewVideo, video, videoTitle } = content;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const prefersReducedMotion = useReducedMotion();
  const previewVideoData = getImgData(previewVideo);
  const videoUrl = typeof video === 'string' ? video : getImgData(video)?.url;

  return (
    <Section color={withBgColor ? theme.colors.neutral.default : undefined} noOverflow>
      {withCircleBackground && <Circle />}
      <CustomContainer>
        <Wrapper>
          <VideoPlayerModal
            modalIsOpen={modalIsOpen}
            closeModal={() => setModalIsOpen(false)}
            videoURL={videoUrl}
          />

          <VideoContainer autoPlay={!prefersReducedMotion} playsInline loop muted>
            <source
              src={getImgSrcWorkaround(previewVideoData?.url ? previewVideoData.url : videoUrl)}
            />
          </VideoContainer>

          <Content>
            <TextContainer>
              <Title size="2xl" as="h5">
                {videoTitle ?? previewVideo.data.attributes.caption}
              </Title>
              <Button variant="white" onClick={() => setModalIsOpen(true)}>
                <IconPlay />
                {buttonLabel || 'Play video'}
              </Button>
            </TextContainer>
          </Content>
        </Wrapper>
      </CustomContainer>
    </Section>
  );
};

export default Video;
