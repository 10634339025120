import QueryString from 'qs';
import cmsClient from '@/lib/cms-client';

class NewsRoomsService {
  static async getPageContent() {
    const query = {
      populate: ['header', 'seo', 'seo.image'],
    };

    const data = await cmsClient(
      `/news-room?${QueryString.stringify(query)}`,
      {
        next: { revalidate: 120 },
      },
      true,
    );

    return data?.data?.attributes ? { ...data.data.attributes } : null;
  }

  static async getShared() {
    const query = {
      populate: ['cta', 'cta.primaryHref', 'cta.secondaryHref'],
    };

    const data = await cmsClient(`/news-room-shared?${QueryString.stringify(query)}`, {
      next: { revalidate: 240 },
    });

    return data?.data?.attributes ? { ...data.data.attributes } : null;
  }
}
export default NewsRoomsService;
