import styled from 'styled-components';
import { Container } from '@/components/core/flexbox';
import { Section as CoreSection } from '@/components/core';

export const Section = styled(CoreSection)`
  position: relative;
  overflow: clip;
`;

export const Circle = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  bottom: calc(50% + 68px);
  background-color: ${(props) => props.theme.colors.white};
  -webkit-clip-path: circle(farthest-side at 50% -8000px);
  clip-path: circle(farthest-side at 50% -8000px);

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    bottom: calc(50% + 52px);
  }
`;

export const Wrapper = styled.div`
  position: relative;
  aspect-ratio: 2/1;
  margin: auto;
  border-radius: 48px;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    border-radius: 16px;
    aspect-ratio: 3/2;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    aspect-ratio: 4/5;
  }
`;

export const CustomContainer = styled(Container)`
  max-width: 1600px;
`;

export const VideoContainer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;

export const Content = styled.div`
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.grey.dark}B3;
  transition: all 120ms ease-out;
  width: 100%;
  top: 0;
  left: 0;
  padding: 24px;
`;

export const TextContainer = styled.div`
  color: ${(props) => props.theme.colors.white};
  display: grid;
  gap: 56px;
  justify-items: center;
  max-width: 640px;
  margin: auto;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 480px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
  }
`;
