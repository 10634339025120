import swordApiClient from '@/lib/sword-api-client';

const savingsPerEnrollment = 1647; // in $

export async function getEnrollmentMetrics() {
  const data = await swordApiClient('/global/v1/website/metrics', {
    next: { revalidate: 86400 },
  });

  return {
    enrollments: data.enrollments,
    savings: data.enrollments * savingsPerEnrollment,
    sessions: data.sessions,
    updatedAt: data.updated_at,
    enrollmentsDaily: data.enrollments - data.enrollments_24h,
    sessionsDaily: data.sessions - data.sessions_24h,
    savingsDaily: (data.enrollments - data.enrollments_24h) * savingsPerEnrollment,
  };
}
