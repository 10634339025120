'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { captureException, withScope } from '@sentry/nextjs';
import { Form, Formik } from 'formik';

import { useCurrentUtm } from '@/utils/useCurrentUtm';

import { Button } from '@/components/core/Button';
import ErrorMessage from '@/components/core/forms/ErrorMessage';
import { FormWrapper } from './styles';
import FormFields from './FormFields';

const GatedResourceForm = ({ postSlug, postType }) => {
  const router = useRouter();
  const [hasError, setHasError] = useState(false);
  const utmParams = useCurrentUtm();

  return (
    <FormWrapper>
      <Formik
        isInitialValid={false}
        initialValues={{
          firstName: '',
          lastName: '',
          company: '',
          jobTitle: '',
          email: '',
          phone: '',
          postType: postType?.name,
          formSlug: postSlug,
          ...utmParams,
        }}
        onSubmit={async (values) => {
          try {
            setHasError(false);

            const res = await fetch('/api/resources/gated/unlock', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(values),
            });

            if (!res.ok) {
              throw new Error(`Failed to submit form: ${JSON.stringify(res)}`);
            }

            router.refresh();
          } catch (e) {
            setHasError(true);
            withScope(function (scope) {
              scope.setTag('form', 'gated');
              scope.setContext('payload', {
                postType: values.postType,
                formSlug: values.formSlug,
                ...utmParams,
              });

              captureException(e);
            });
          }
        }}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form>
            <FormFields values={values} errors={errors} touched={touched} />

            <ErrorMessage
              className="fullWidth"
              hasError={hasError}
              label="There was an error submitting your registration. Please try again."
            />

            <Button className="customButton" type="submit" loading={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default GatedResourceForm;
